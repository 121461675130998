export const quoteColumns = [
  {
    id: "description",
    heading: "Item Description",
  },
  {
    id: "erect",
    heading: "Erect & Dismantle (p/u)",
  },
  {
    id: "fee",
    heading: "Weekly Hire Fee",
  },
];

export const zoneTotalsColumns = [
  {
    id: "zone",
    heading: "Phase",
  },
  {
    id: "label",
    heading: "Phase Label",
  },
  {
    id: "erectZone",
    heading: "Erect & Dismantle Total",
  },
  {
    id: "hireTotal",
    heading: "Weekly Hire Total",
  },
  {
    id: "zoneTotal",
    heading: "Total Cost",
  },
];

export const additionalItemsColumns = [
  {
    id: "additionalDesc",
    heading: "Description",
  },
  {
    id: "additionalTotal",
    heading: "Total Costs",
  },
];

export const summaryCostColumns = [
  {
    id: "summaryConcept",
    heading: "Concept",
  },
  {
    id: "summaryTotalCost",
    heading: "Total Cost"
  }
];
