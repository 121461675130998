import { v4 as uuidv4 } from "uuid";

export function formatQuoteLines(lines, quoteId, type) {
  return lines.map((line) => ({
    ...(type === "edit" && { id: line.id || null }),
    quote_id: quoteId,
    zone: line.zone,
    zone_label: line.zoneLabel,
    type: line.type || "",
    description: line.description,
    quantity: line.quantity,
    length: line.length,
    height: line.height,
    width: line.width,
    total_dimensions: line.totalDimensions || 0,
    weekly_duration: line.duration || 0,
    transport: line.transport || 0,
    erect_dismantle: line.dismantle,
    weekly_fee: line.hireFee || 0,
    totalDays: line.totalDays || 0,
    total: String(line.total) || "",
    percentageWeeklyHireFee: Number(line.percentageWeeklyHireFee)
  }));
}

export function formatQuotePortalLines(lines, quoteId) {
  return lines.map((item) => ({
    id: item.id,
    zone: String(item.zone).trim(),
    zoneLabel: item.zone_label,
    type: item.type,
    description: item.description,
    quantity: item.quantity || 1,
    length: item.length,
    height: item.height,
    width: item.width,
    totalDimensions: item.total_dimensions || 0,
    duration: item.weekly_duration || 0,
    transport: item.transport || 0,
    dismantle: item.erect_dismantle,
    hireFee: item.weekly_fee || 0,
    totalDays: item.totalDays || 0,
    total: item.total || 0,
    percentageWeeklyHireFee: item.percentageWeeklyHireFee || 0
  }));
}

export function formatZones(zones, quoteId, type = "") {
  return zones.map((zone) => ({
    ...(type === "edit" && { id: zone.zone_id || null }),
    quote: quoteId,
    zone_id: zone.id,
    zone_label: zone.label,
  }));
}

export function formatAddons(addons, quoteId) {
  return addons.map((addon) => ({
    id: addon.id,
    quote: quoteId,
    type: addon.type || "Additional",
    description: addon.description,
    duration: Number(addon.duration) || null,
    hire_fee: Number(addon.hireFee) || null,
    fixed_charge: Number(addon.fixedCharge) || null,
    total: Number(addon.totalCost) || null,
  }));
}

export function formatRates(rates, quoteId, type = "create") {
  return rates.map((rate) => ({
    ...(type === "edit" && { id: rate.id || null }),
    quote_id: quoteId,
    service: rate.service,
    erect_fee: rate.erect_fee,
    hire_fee: rate.hire_fee,
    type: rate.type
  }));
}

export function formatPortalAddons(addons) {
  return addons.map((addon) => ({
    id: addon.id,
    type: addon.type,
    description: addon.description,
    duration: addon.duration,
    hireFee: addon.hire_fee,
    fixedCharge: addon.fixed_charge,
    totalCost: Number(addon.total),
  }));
}

export const tabs = [
  { name: "Phases", href: "#", id: 1 },
  { name: "Rates", href: "#", id: 2 },
];

export const Admintabs = [
  { name: "Phases", href: "#", id: 1 },
  { name: "Rates", href: "#", id: 2 },
  { name: "Rates Edition", href: "#", id: 3 },
];

export const zoneOptions = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" }
];

export const quoteRates = [
  {
    id: uuidv4(),
    service: "Roof",
    erect: "95.00",
    hire: "12.00",
  },
  {
    id: uuidv4(),
    service: "Propping",
    erect: "22.00",
    hire: "2.00",
  },
  {
    service: "Edge Protection",
    erect: "28.00",
    hire: "0.70",
  },
  {
    service: "Shrinkwrap",
    erect: "10.00",
    hire: "0.00",
  },
  {
    service: "Geda 1200",
    erect: "320.00",
    hire: "5.00",
  },
];

export function formatQuoteAdditionalConditions(additionalConditions, quoteId, type="create") {
  return additionalConditions.map((condition) => ({
    ...(type === "edit" && { id: condition.id || null }),
    quote_id: quoteId,
    additional_condition_id: condition.additional_condition_id,
    checked: condition.checked,
    name: condition.name,
    title: condition.title,
    text: condition.text
  }));
}

export const quoteTerms = `
3rd Party - We reserve the right to use a third party contractor to complete any of the services required.
50% PrePay - 50% prepayment of the installation cost will be required before starting works.
90 Days - This quote is valid for 90 days from the date of issue.
Access/Ladder - All access to scaffold will be gained via ladder
Act 2002 - All hireage and sales are subject to The Construction Contracts Act 2002.
All floor protection – Any floor protection required is main contractors responsibility.
Allowance for Access - No allowance has been made for access machines required for install and dismantle. If one is not provided extra changes will incur
Alt Fall Prevention - The CLIENT must use alternative fall prevention provisions on unguarded edges.
Back Propping - No back propping to the roof area as been has been allowed for. If required this will be an extra cost.
BPG - All scaffolds will be erected in accordance with NZ Best Practice Guidelines for Scaffolding.
Cleaning Fee - Any industrial coatings damage will incur cleaning fees
Clear/Unobstructed - Price assumes clear and unobstructed access to site, including parking for truck during erection & dismantle.
Crane Work - Any crane work required during the erect and dismantle of the project will be supplied/organized by the main contractor at no cost to Verge NZ.
Damaged - Equipment returned damaged or needing repair will be charged at full replacement cost
Driveway Blocked - Driveway will be blocked by Scaffold and/or Edge Protection
Ejots - Scaffold will be tied to building/house with drilled in anchors. Client is responsible for arranging holes to be filled and touched up.
Engineer - Design and inspection by a Engineer may be required at the client’s cost.
Extra Labour $75 per Hr - Any additional work or alterations will be charged at $75 + GST per man, per hour.
Final Plans - Prices may change subject to final plans and build program
Forbidden Alterations - It is forbidden for the scaffold or edge protection to be altered by anyone other than an employee of Verge NZ. If altered, variation charges will apply.
Garage Blocked - Garage/Carport will be blocked by Scaffold and/or Edge Protection
Gardens - Our Organisation will take all practical care but will not be responsible for damage to the gardens.
Gear Available - Pricing is subject to the availability of scaffolding material at the time of project commencement
Home Power - Power lines to home are to be sleeved of for protection. This is to be organised by client and completed previous to our arrival
Level/Compacted - Excavations to be filled, compacted, and levelled before scaffold is erected.
Main Power - STREET CABLES within 6 metres of Scaffolding being built: Client to arrange with local electrical lines provider and pay any associated costs to make network (street) cables safe.
Min 1 Week - This quote is subject to: One week Minimum hire period. (Daily Hire Rate x 7 days).
Neighbouring Lower Roof - Permission to install scaffold on the neighbouring lowers roofs to the organized by the main contractor
Neighbours - Client to arrange permission from Neighbours to build/land scaffold on neighbouring property.
Normal Hours - Price assumes all work will be carried out in normal hours.
Physical Measure - Quoted from Plans only - Price is subject to a physical measure.
Retentions - Retentions will not apply to this contract.
Roof - Our Organisation will take all practical care but will not be responsible for damage to roofs.
Security - Client will be responsible for any security measures required while scaffold is in place.
Shrinkwrap - Scaffold wrapped with shrinkwrap is not guaranteed in winds over 60km/hr. Our organisation will not be responsible for any damages or costs incurred due to failure of wrap and/or scaffold. The warranty on the product is 12 months.
Sky/Aerial - Our Organisation will take all practical care but will not be responsible for damage, or interference of signal, to TV Aerials or Sky Dishes.
Traffic Management - All traffic management requirements and cost are to be handled by the main contractor. If Verge NZ is required to organize the required traffic management the costs will be on charged to the main contractor.
Trim Maybe - Trees may need to be trimmed to erect scaffold, price does not include disposal or trimmed vegetation. Our Organisation will take all practical care but will not be responsible for damage to tree.
Trim Must - Trees/Plants/Gardens must be trimmed by client to make space for scaffold to be erected.
Uninterrupted Work - This quote is based on Verge NZ been able to carry out their work uninterrupted or delayed. If interrupted or delayed stand-down rates will incur
Weather - We can only install/dismantle the scaffold/edge protection in suitable weather conditions and will not be liable for any loss incurred for delays caused by weather. This includes any adverse weather leading up to the agreed installation date that has delayed other jobs.
Weekly Inspection - If a weekly inspection is requested but has not been shown in the quote, then a charge of $60 per inspection will be added to invoice.

`;
