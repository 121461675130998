import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import supabase from "../supabase";

import { useNotificationStore } from "../../store/notifications";
import { updateTask } from "../Jobs";

export async function updateInvoice({ newPayload, id, type }) {
    const { data, error } = await supabase
        .from(type === "Weekly Hire" ? "weekly_hire_invoices" : "edinvoices")
        .update(newPayload)
        .match({ id });

    if (error) {
        throw new Error(error.message);
    }

    return data;
}

export const useUpdateInvoice = () => {
    const { addNotification } = useNotificationStore();
    const queryClient = useQueryClient();

    return useMutation((invoice) => updateInvoice(invoice), {
        onSuccess: (data) => {
            queryClient.refetchQueries("weeklyHires");
            queryClient.refetchQueries("EDInvoices");

            addNotification({
                isSuccess: true,
                heading: "Success!",
                content: `Successfully updated invoice.`,
            });
        },

        onError: (err) => {
            addNotification({
                isSuccess: false,
                heading: "Failed updating invoice",
                content: err?.message,
            });
        },
        mutationFn: updateInvoice,
    });
};
