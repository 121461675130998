import { useQuery } from "react-query";
import supabase from "../../supabase";

export async function fetchQuoteAddons(quote) {
  const { data, error } = await supabase.from("quote_addons").select("*").eq("quote", Number(quote));
  if (error) {
    throw new Error(error.message);
  }
  return data;
}

export function useFetchQuoteAddons(quote) {
  return useQuery("quote_addons", () => fetchQuoteAddons(quote));
}
