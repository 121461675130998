import { CheckIcon, XIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { Input, TextArea, Button, ConfirmationDialog } from "../../common";
import { useNotificationStore } from "../../store/notifications";
import { uploadQuote } from "./pdf/uploadQuote";
import { QuotesApi } from "../../api";

export const EmailSent = ({ quoteId, emailStatus, quote }) => {
  const { addNotification } = useNotificationStore();
  const [isLoading, setIsLoading] = useState(false);
  const updateQuoteMutation = QuotesApi.useUpdateQuote();

  const [state, setState] = useState({
    subject: `Quote ${quote.quote_num}`,
    body: `Hi ${quote.client_contacts?.name || ""},

Thank you for the opportunity to be involved with this project. Please see our quote attached, which is based on the information provided to us and/or a site visit.

Client Name: ${quote.clients.client_name}
Job Reference: ${quote.street_1} ${quote.street_2} ${quote.city}
Quote/Job #: ${quote.quote_num}
Attention of: ${quote.client_contacts?.name || ""}

To accept our quote, please either reply to this email with your preferred start date and purchase order number (if applicable); OR use this link to review and accept our quote.

If you have any questions or would like to discuss further, please don't hesitate to get in touch.

We look forward to working with you on this project.
Thanks again,

${quote?.staff?.staff_name || ""} ${quote?.staff?.staff_last_name || ""} 

`
  });

  const newStatus = emailStatus === "Ready to send" ? "Sent" : "Ready to send";

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setState({
      ...state,
      [id]: value,
    });
  };


  return (
    <ConfirmationDialog
      isDone={updateQuoteMutation.isSuccess}
      icon="info"
      title="Sent Quote"
      body={`Are you sure you wish to mark this quote as ${newStatus} ? ${newStatus === 'Sent' ? "This action will send the quote to the client" : ''}`}
      triggerButton={
        <button
          type="button"
          id={quoteId}
          className="ml-3 inline-flex items-center text-sm font-medium focus:outline-none hover:text-green-400"
        >
          {emailStatus === "Ready to send" && < CheckIcon
            className="-ml-0.5 mr-2 h-4 w-4 text-green-400"
            aria-hidden="true"
          />}
          {emailStatus === "Sent" && <XIcon
            className="-ml-0.5 mr-2 h-4 w-4 text-red-400"
            aria-hidden="true"
          />}
          {emailStatus}
        </button >
      }
      confirmButton={
        <Button
          isLoading={isLoading}
          variant="approve"
          onClick={async (e) => {
            if (!state.body || !state.subject) {
              return
            }
            setIsLoading(true);
            if (newStatus === "Sent") {
              const urlQuote = await uploadQuote(quoteId);
              const body = state.body.split("\n").join(" <br /> ")
              fetch("https://verge-server.herokuapp.com/api/quote-email", {
                method: 'POST', // or 'PUT'
                body: JSON.stringify({
                  subject: state.subject,
                  text: `${body}  <br />  <a href="${urlQuote}">Link</a>`,
                  emailTo: quote?.client_contacts?.email,
                  sender: quote?.staff?.email || null,
                  urlQuote,
                }),
                headers: {
                  'Content-Type': 'application/json'
                }
              }).then(res => res.json())
                .catch(error => addNotification({
                  isSuccess: false,
                  heading: "Error!",
                  content: `Network Error With Mail.`,
                }))
                .then(async response => {
                  let isSuccess = true;
                  let content = "Success!";
                  if (response.err) {
                    isSuccess = false;
                    content = "Error!";
                  }
                  const heading = response.message;
                  addNotification({
                    isSuccess,
                    heading,
                    content
                  });
                  try {
                    const result = await updateQuoteMutation.mutateAsync({
                      quote: { emailStatus: newStatus },
                      quoteId,
                    })
                    console.log(result)
                    window.location.reload();
                  } catch (error) {
                    console.log("error")
                  }
                  setIsLoading(false);
                })
            } else {
              try {
                const result = await updateQuoteMutation.mutateAsync({
                  quote: { emailStatus: newStatus },
                  quoteId,
                })
                console.log(result)
                window.location.reload();
              } catch (error) {
                console.log("error")
              }
              setIsLoading(false);
            }
          }}
        >
          Mark as {newStatus}
        </Button >
      }
    >
      <>
        {newStatus === 'Sent' && <>
          <div className="flex">
            <div className="w-1/2">
              <Input
                title="Subject"
                id="subject"
                type="text"
                value={state.subject}
                handleChange={handleInputChange}
                error={!state.subject ? "Subject Is Requied" : null}
              />
            </div>
          </div>
          <TextArea
            title="Body Text"
            id="body"
            type="text"
            value={state.body}
            handleChange={handleInputChange}
            rows={8}
            error={!state.body ? "Body Is Requied" : null}
          />
        </>}
      </>
    </ConfirmationDialog>
  );
};