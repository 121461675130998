import { Checkbox } from "primereact/checkbox";

export const TickBox = (props) => {
  const handleCheckboxChange = (event) => {
    props.setFieldValue("terms", `${props.value} ${event.target.checked}`);
    const affectedCheckbox = props.sharedCheckBoxesState.find(
      (cb) => cb.name === event.target.name,
    );
    affectedCheckbox.checked = event.target.checked;
    props.setsharedCheckBoxesState(props.sharedCheckBoxesState);
  };

  return (
    <div className="grid grid-cols-2 gap-4">
      {props.sharedCheckBoxesState.map((line, index) => (
        <div className="flex align-items-center">
          <Checkbox
            inputId={`${line}`}
            name={`${line.name}`}
            value={line.title}
            onChange={handleCheckboxChange}
            checked={props.sharedCheckBoxesState[index].checked}
          />
          <label htmlFor={`${line}`} className="ml-2 text-sm">
            {props.sharedCheckBoxesState[index].title}
          </label>
        </div>
      ))}
    </div>
  );
};
