/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import moment from "moment";
import DatePicker from "react-datepicker";
import { InputText } from "primereact/inputtext";
import { SideModal, Input, TextArea, Dropdown, Spinner, DateSelect } from "../../common";
import { WeeklyHireApi } from "../../api";


import "react-datepicker/dist/react-datepicker.css";



const yesNoOptions = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
]

export function CreateEditWeeklyInvoice({ job_id, invoiceID, open, setOpen }) {
    const createinvoiceMutation = WeeklyHireApi.useCreateHire();
    const UpdateinvoiceMutation = WeeklyHireApi.useUpdateHire();

    const [invoiceData, setinvoiceData] = useState(null);

    useEffect(() => {
        if (invoiceID && !invoiceData) {
            WeeklyHireApi.fetchHireById(invoiceID).then((data) => setinvoiceData(data[0]))
        }
    });

    if (invoiceID && !invoiceData) {
        return (
            <div className="w-full h-48 flex justify-center items-center">
                <Spinner size="lg" />
            </div>
        )
    }

    return (
        <div>
            <Formik
                initialValues={{
                    description: invoiceData.description,
                    on_hire: invoiceData.on_hire,
                    completed: invoiceData.completed,
                    date_on_hire: invoiceData.date_on_hire,
                    completed_date: invoiceData.completed_date,
                }}
                onSubmit={async (values, { resetForm }) => {
                    const { description, on_hire, completed, date_on_hire, completed_date } = values;

                    let invoicePayload = {};

                    if (invoiceID) {
                        invoicePayload = {
                            id: invoiceID,
                            description, on_hire, completed,
                            date_on_hire: date_on_hire ?
                                moment(date_on_hire, "DD/MM/YYYY").format("DD/MM/YYYY")
                                : "",
                            completed_date: completed_date ?
                                moment(completed_date, "DD/MM/YYYY").format("DD/MM/YYYY")
                                : "",
                        };
                    }
                    else {
                        invoicePayload = {};
                    }

                    try {
                        let result;
                        if (invoiceID) {
                            result = await UpdateinvoiceMutation.mutateAsync({ hire: invoicePayload, hireId: invoiceID });
                        }
                        else {
                            // result = await createinvoiceMutation.mutateAsync(invoicePayload);
                        }

                        setinvoiceData([]);
                        resetForm();
                        setOpen();

                    } catch (err) {
                        console.log("ERROR CREATING/UPDATING Invoice", err);
                    }
                }}

            >
                {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    setFieldTouched,
                }) => (
                    <SideModal
                        heading={`${!invoiceID ? "Create new" : "Edit"}`}
                        open={open}
                        setOpen={setOpen}
                        handleSubmit={handleSubmit}
                        isLoading={isSubmitting}
                        formType={`${!invoiceID ? "create" : "update"}`}
                    >

                        <>
                            {invoiceID}
                            <div className="flex items-center">
                                <Input
                                    title="Description"
                                    id="description"
                                    type="text"
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    value={values.description}
                                />
                                <Dropdown
                                    label="On Hire"
                                    id="on_hire"
                                    options={yesNoOptions}
                                    value={values.on_hire}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                />
                            </div>
                            <div>
                                <Input
                                    title="% Complete"
                                    id="completed"
                                    type="number"
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    value={values.completed}
                                />
                            </div>

                            <div className="flex items-center">
                                <DateSelect
                                    title="Date On Hire"
                                    id="date_on_hire"
                                    value={values.date_on_hire}
                                    onChange={setFieldValue}
                                />
                                <DateSelect
                                    title="Complete Date"
                                    id="completed_date"
                                    value={values.completed_date}
                                    onChange={setFieldValue}
                                />
                            </div>
                        </>

                    </SideModal>
                )}
            </Formik>
        </div>
    );
}