import { useMutation, useQueryClient } from "react-query";
import supabase from "../../supabase";
import { useNotificationStore } from "../../../store/notifications";

export async function updateTaskSplit({splitValue, taskId}) {
    console.log(splitValue,taskId);
    const { data, error } = await supabase
    .from("job_tasks")
    .update({ split: splitValue })
    .match({ id: taskId });
  if (error) {
    console.log(error,"error")
    throw new Error(error.message);
  }
  console.log(data,"data")
  return data;
}

export const useUpdateTaskSplit = () => {
  const { addNotification } = useNotificationStore();
  const queryClient = useQueryClient();

  return useMutation((task) => updateTaskSplit(task), {
    onSuccess: (data) => {
      queryClient.refetchQueries("EDInvoices");

      addNotification({
        isSuccess: true,
        heading: "Success!",
        content: `Successfully updated task.`,
      });
    },

    onError: (err) => {
      addNotification({
        isSuccess: false,
        heading: "Failed updating ED Invoice",
        content: err?.message,
      });
    },
    mutationFn: updateTaskSplit,
  });
};