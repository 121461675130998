import React from "react";

import { Container, HeadingContainer, StaffContainer, TaskContainer } from "./styledComponents";

const EventView = ({ event }) => {
  const color = {
    '':'#10B981',
    null: "#10B981",
    Install: "lightgreen",
    Dismantle: "red",
    Remedial: "#fbb724",
    "Commercial Edge Protection Install": "lightblue",
    "Commercial Edge Protection Dismantle": "maroon",
    "Gable On Hire": "purple",
    "Gable Off Hire": "hotpink",
    "Aluminium Mobile On Hire (and Dry Hire)": "royalblue",
    "Aluminium Mobile Off Hire (and Dry Hire)": "darkblue",
    Adjustment: "darkgreen",
    Variation: "orange",
    Cartage: "grey",
    Message: "black",
  };
  const eventProp = event.extendedProps;
  
  const styles = () => ({
    color: "white",
    backgroundColor: color[eventProp.type],
  });

  return (
    <Container
      padding="0.5px"
      fontSize="0.8rem"
      color={styles().color}
      backgroundColor={styles().backgroundColor}
    >

      <StaffContainer>
        <b>Job:</b> {eventProp.job}
      </StaffContainer>
      <StaffContainer>
        <b>Team Leader:</b> {eventProp.teamLeader}
      </StaffContainer>
    </Container>
  );
};

export default EventView;
