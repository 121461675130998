import { XIcon } from "@heroicons/react/outline";
import { useState, useEffect } from "react";
import { Dropdown } from "../../../common/Dropdown";

import { numberFormat } from "../../../utils";
import { ConfirmationDialog } from "../../../common/Confirmation/Confirmation";
import { Button } from "../../../common";

export function Line({
  zoneOptions,
  zoneLabels,
  quoteLine,
  handleRemove,
  index,
  handleProductLineChange,
  handleDimensionsLineChange,
  handleEDChangeLineChange,
  handleSqMLineChange,
  rates,
  estimatedWay,
}) {
  console.log(quoteLine, "quoteLine");
  const typeOptions = rates
    .filter((e) => e.type === "General" || e.type === "Both")
    .map((e) => ({ value: e.service, label: e.service }));
  const [label, setLabel] = useState("");
  const [zone, setZone] = useState(!quoteLine?.zone);
  const [serviceType, setServiceType] = useState(null);
  const rateData = rates.find((rate) => rate.service === serviceType);
  const { quantity, dismantle, percentageWeeklyHireFee, staffReq, hireFee } = quoteLine;
  useEffect(() => {
    setServiceType(quoteLine?.type);
  }, [quoteLine?.type]);

  useEffect(() => {
    const rateData = rates.find((rate) => rate.service === serviceType);
    handleDimensionsLineChange(index, quantity, rateData, percentageWeeklyHireFee, staffReq);
  }, [quantity, percentageWeeklyHireFee, staffReq]);

  useEffect(() => {
    const rateData = rates.find((rate) => rate.service === serviceType);
    handleEDChangeLineChange(index, dismantle, rateData, percentageWeeklyHireFee, hireFee);
  }, [dismantle, percentageWeeklyHireFee, rates, hireFee]);

  useEffect(() => {
    const rateData = rates.find((rate) => rate.service === serviceType);
    handleSqMLineChange(
      index,
      quantity,
      rateData,
      percentageWeeklyHireFee,
      quoteLine.length,
      quoteLine.height,
      quoteLine.width,
    );
  }, [quantity, percentageWeeklyHireFee, quoteLine.length, quoteLine.height, quoteLine.width]);

  useEffect(() => {
    const findLabel = zoneLabels.find((el) => Number(el.id) === Number(quoteLine.zone));

    setLabel(findLabel?.label);

    handleProductLineChange(index, "zoneLabel", findLabel?.label);
  }, [quoteLine.zone, zoneOptions]);

  return (
    <tr key={index}>
      <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-20">
        <Dropdown
          hasLabel={false}
          options={zoneOptions}
          id={`zone${index}`}
          value={quoteLine.zone}
          onChange={(e) => {
            setZone(false);
            handleProductLineChange(index, "zone", e);
          }}
          repeatingForm
        />
      </td>
      <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-40">
        <input
          id={`zoneLabel${index}`}
          type="text"
          className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-600 focus:outline-none"
          value={label || ""}
          name="zoneLabel"
          disabled
          onChange={(e) => handleProductLineChange(index, "zoneLabel", e.target.value)}
        />
      </td>
      <td className="h-6 px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-52">
        <Dropdown
          hasLabel={false}
          options={typeOptions}
          id={`type${index}`}
          value={quoteLine.type}
          disabled={zone}
          onChange={(e) => {
            setServiceType(e);
            handleProductLineChange(index, "type", e);
          }}
          repeatingForm
        />
      </td>
      <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-48">
        <input
          id={`description${index}`}
          type="text"
          className="h-7 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none"
          value={quoteLine.description}
          onChange={(e) => handleProductLineChange(index, "description", e.target.value)}
        />
      </td>
      <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
        <input
          id={`quantity${index}`}
          type="number"
          className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-500 focus:outline-none"
          value={quoteLine.quantity}
          disabled={!serviceType || estimatedWay === "Total ED"}
          onChange={(e) => handleProductLineChange(index, "quantity", e.target.value)}
        />
      </td>
      {estimatedWay === "SqM" ? (
        <>
          <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
            <input
              id={`length${index}`}
              type="number"
              className="h-7 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none"
              value={quoteLine.length}
              onChange={(e) => handleProductLineChange(index, "length", Number(e.target.value))}
            />
          </td>
          <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
            <input
              id={`height${index}`}
              type="number"
              className="h-7 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none"
              value={quoteLine.height}
              onChange={(e) => handleProductLineChange(index, "height", Number(e.target.value))}
            />
          </td>
          <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
            <input
              id={`width${index}`}
              type="number"
              className="h-7 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none"
              value={quoteLine.width}
              onChange={(e) => handleProductLineChange(index, "width", Number(e.target.value))}
            />
          </td>
          <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
            <input
              id={`dimensions${index}`}
              type="number"
              className="h-7 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none"
              value={
                quoteLine.length && quoteLine.height && quoteLine.width
                  ? quoteLine.length * quoteLine.height * quoteLine.width
                  : 0
              }
              disabled
            />
          </td>
        </>
      ) : estimatedWay === "Hours" ? (
        <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
          <input
            id={`staffReq${index}`}
            type="number"
            className="h-7 text-sm rounded-md w-full border text-gray-600 focus:outline-none"
            value={quoteLine.staffReq}
            onChange={(e) => handleProductLineChange(index, "staffReq", e.target.value)}
          />
        </td>
      ) : null}
      <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
        <input
          id={`dismantle${index}`}
          type="text"
          className="h-7 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none"
          value={`$${Number(quoteLine.dismantle)}`}
          onChange={(e) => {
            const newValue = e.target.value.replace(/\$/g, '');
            handleProductLineChange(index, "dismantle", Number(newValue));
          }}
          disabled={estimatedWay !== "Total ED"}
        />
      </td>
      {/* <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
        <input
          id={`percentageWeeklyHireFee${index}`}
          type="text"
          className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-600 focus:outline-none"
          value={Number(quoteLine.percentageWeeklyHireFee)}
          onChange={(e) =>
            handleProductLineChange(index, "percentageWeeklyHireFee", e.target.value)
          }
        />
      </td> */}
      <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
        <input
         id={`hireFee${index}`}
         type="text"
         className="h-7 text-sm rounded-md w-full border border-gray-300 text-gray-900 focus:outline-none"
         value={`$${Number(quoteLine.hireFee)}`}
         onChange={(e) => {
           const newValue = e.target.value.replace(/\$/g, '');
           handleProductLineChange(index, "hireFee", Number(newValue));
         }}
       />
      </td>
      <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
        <input
          id={`total${index}`}
          type="text"
          className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-500 focus:outline-none"
          value={numberFormat.format(Number(quoteLine.total))}
          onChange={(e) => handleProductLineChange(index, "total", e.target.value)}
          disabled
        />
      </td>
      <td className="px-2 py-1 text-tiny bg-white border border-gray-100 whitespace-nowrap w-8">
        <ConfirmationDialog
          icon="danger"
          title="Delete Line Item"
          body="Are you sure you want to delete this item? This action is unrecoverable!"
          triggerButton={
            <button type="button">
              <XIcon className="flex-shrink-0 h-4 w-4 text-red-500" aria-hidden="true" />
            </button>
          }
          confirmButton={
            <Button
              variant="danger"
              className="bg-red-600 text-white"
              onClick={async (e) => {
                e.preventDefault();
                handleRemove(index, quoteLine.id);
              }}
            >
              Delete Line
            </Button>
          }
        />
      </td>
    </tr>
  );
}
